import React, { Component } from 'react';
import { Link } from "react-router-dom";
import gsap from 'gsap';

class Item extends Component {

    data = this.props.Data;
    language = this.props.Language;

    componentDidMount() {

        // gsap.fromTo(".category-item:nth-child(1)", { opacity: 0, x:-1000 }, { opacity: 1, x:0, duration: 1 });
        // gsap.fromTo(".category-item:nth-child(2)", { opacity: 0, x:500 }, { opacity: 1, x:0, duration: 1.5 });
        // gsap.fromTo(".category-item:nth-child(3)", { opacity: 0, x:-1000 }, { opacity: 1, x:0, duration: 2 });

        // gsap.fromTo(".category-item:nth-child(1) img", { x:-1000 }, { opacity: 1, x:0, duration: 2 });
        // gsap.fromTo(".category-item:nth-child(2) img", { x:500 }, { opacity: 1, x:0, duration: 3 });
        // gsap.fromTo(".category-item:nth-child(3) img", { x:-1000 }, { opacity: 1, x:0, duration: 4 });
    }

    render() {
        return (

            <Link className="category-item" to={"/brand/" + this.data['id']} onClick={ () => this.props.handleSelectedCategory(this.data['id'])}>
                
                <div className='category-item-image'>
                    <img src={this.data.logo} alt="" />
                </div>

                <div className='category-item-text'>
                    <h2 style={ this.props.Language == "en"? {'fontFamily':'Urbanist'}: {}}> 
                        {
                            this.props.Language == "en" ? this.data['name_eng']
                            : this.props.Language == "krd" ? this.data['name_krd']
                            : this.data['name_ar']
                        }
                    </h2>
                </div>

            </Link>
        );
    }
}

export default Item;