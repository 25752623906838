import React, { Component } from 'react';
import axios from 'axios';
import { Link, Navigate } from "react-router-dom";
import Category from './category';
import List from './list/list';
import Two from './list/two';
import Large from './list/large';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Loader from '../general/loader';
import Subcategory from './subcategory';
import backArrow from '../../assets/backArrow.png'
import 'react-photo-view/dist/react-photo-view.css';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import Brand from './brand';

class Menu extends Component {

    settings = this.props.Settings;
    language = this.props.Language;

    state = {
        categories: [],
        selectCategory: [],
        brands: [],
        selectBrand: [],
        items: [],
        loadCat: false,
        load: false,
        listview: "two",
        showLangSelect: false,
        slide: [],
        loadSlide: false,
    }

    async componentDidMount(){

        // Get categories
        const {data} = await axios.get("https://manage.caviarmenu.com/api/categoriesWithBrands/sgtcompany");
        this.setState({ categories: data }, ()=>{
            this.getCategoryName(-1);
            this.setState({loadCat: true});
        });

        {
            const {data} = await axios.get("https://manage.caviarmenu.com/api/brandItems/sgtcompany");
            this.setState({ items: data }, ()=>{
                this.setState({load: true});
            });
        }

        {
            // Get Slideshows
            const {data} = await axios.get("https://manage.caviarmenu.com/api/slideshow/sgtcompany");
            this.setState({ slide: data }, ()=>{
                this.setState({loadSlide: true});
            });
        }

        this.layoutDirection();

    }

    layoutDirection() {
        if(this.props.Language != "en")
            document.getElementById("itemContainer").style.direction = "rtl";
        else
            document.getElementById("itemContainer").style.direction = "ltr";
    }

    getCategoryName(id) {
        
        if(id !== -1) {

            const selectedCategory = this.state.categories.find(cat => cat.id == id);
            this.setState({
                selectCategory: selectedCategory,
                brands: selectedCategory.brands,
                selectBrand: selectedCategory.brands[0],
            });
        }
        else
            this.setState({ selectCategory: this.state.categories[0], brands: this.state.categories[0].brands, selectBrand: this.state.categories[0].brands[0], });
            
    }

    handleChange = async e => {
        await this.props.handleLangaugeSubmit(e);
        this.layoutDirection();
    }

    handleCategory = async catId => {
        this.setState({load: false});
        this.getCategoryName(catId);
        this.setState({load: true});        
    }

    handleBrand = async brand => {
        this.setState({load: false});
        this.setState({selectBrand: brand});
        this.setState({load: true}); 
    }

    handleListview = style => {
        this.setState({listview: style});
    }

    handleShowLangSelect = e => {
        this.setState({showLangSelect: e});
    }

    openUrl = (index, item) => {
        window.open(item.props.url,'_blank');
    }

   
    render() {

        const isLtr = (this.props.Language == "en");
        const {brands} = this.state;
        const brandLength = Object.values(brands).length;

        return (
            <div className='menu-items'>
                <div className="head">


                <Link to={"/home"}>
                    <img src={this.settings.logo} alt="" />
                </Link>
                    
                    
                    <div className="language">
                        <div 
                                className='language-active' 
                                // style={{'direction': `${this.props.Language != "en"? 'rtl': 'ltr'}`}}
                                onClick={ () => this.handleShowLangSelect(true) }>
                                <i className="fa-solid fa-globe"></i>
                                {
                                    this.props.Language == "en"? 'En'
                                    : this.props.Language == "krd"? 'Ku'
                                    : 'Ar'
                                }
                        </div>
                    </div>

                    <div className='menu-feedback'>
                        <Link to={"/feedback"} style={ this.props.Language == "en"? {'fontFamily':'Urbanist'}: {}}>
                            {
                                this.props.Language == "en" ? 'Feedback'
                                : this.props.Language == "krd" ? 'فیدباك'
                                : 'فیدباك'
                            } 
                        </Link>
                    </div>

                </div>


                <div className='carousel'>
                    {
                        this.state.loadSlide 
                        ? 
                            <Carousel autoPlay={true} showThumbs={false} showStatus={false} showArrows={false} infiniteLoop={true} onClickItem = {(index, item) => this.openUrl(index, item)}>
                                { this.state.slide.map( slide => <img key={slide.id} src={slide.image} url={slide.url} /> )}
                            </Carousel>
                        : 
                        ''
                    }
                </div>

                <Link to={"/brands"} className='brands'>
                    <h4 style={ this.props.Language == "en"? {'fontFamily':'Urbanist'}: {}}>
                        {
                            this.props.Language == "en"? 'View all brands'
                            : this.props.Language == "krd"? 'پێشاندانا هەمی براندان'
                            : 'عرض جميع الماركات'
                        }
                    </h4>
                </Link>


                <div 
                className='categories'
                style={{'direction': `${this.props.Language != "en"? 'rtl': 'ltr'}`}} >
                    {this.state.loadCat ? (
                        <>
                             {this.state.categories.map((cat) => (
                                <Category
                                    key={cat.id}
                                    Data={cat}
                                    Language={this.props.Language}
                                    handleCategory={this.handleCategory}
                                    Active={this.state.selectCategory.id === cat.id}
                                />
                            ))}
                        </>
                    ) : (
                        <Loader />
                    )}
                </div>


                <div 
                className='category-brands'
                style={{'direction': `${this.props.Language != "en"? 'rtl': 'ltr'}`}} >

                    { (this.state.loadCat && Object.values(this.state.brands).length > 0) ? (
                        <>
                             {Object.values(brands)?.map((brand) => (
                                <Brand
                                    key={brand.id}
                                    Data={brand}
                                    Language={this.props.Language}
                                    handleBrand={this.handleBrand}
                                    Active={this.state.selectBrand.id === brand.id}
                                />
                            ))}
                        </>
                    ) : (
                        <Loader />
                    )}
                </div>

                <div className='items' id='itemContainer'>

                <div className="head-listview">
                    {/* <h5 className="category-name">
                    {
                        this.props.Language == "en" ? this.state.selectCategory.name_eng
                        : this.props.Language == "krd" ? this.state.selectCategory.name_krd
                        : this.state.selectCategory.name_ar
                    }
                    </h5>

                     */}
                    
                </div>

                

                                                    {
                                                        this.state.load ?
                                                            <PhotoProvider maskOpacity={0.7} speed = {() => 25} photoClassName={'photoProviderImg'} overlayRender={ ({ images, index }) => {
                                                                
                                                                if(!images[index]){
                                                                    return null;
                                                                }
                                                                const data = images[index].overlay;                                                                
                                                                
                                                                return (
                                                                    <div className={`item-photo-view`} style={ {'direction': isLtr? "ltr" : "rtl"} }>
                                                                        <div>
                                                                            <h5 style={this.props.Language == "en"? {letterSpacing:'1px'}: {}}>
                                                                                {
                                                                                    this.props.Language == "en" ? data.name_eng
                                                                                    : this.props.Language == "krd" ? data.name_krd
                                                                                    : data.name_ar
                                                                                }          
                                                                            </h5>
                                                                        </div>

                                                                        <div className='barcode'>
                                                                            <img src={data.barcode} alt="" />
                                                                        </div>
                                                                        

                                                                        <div className='price'>
                                                                            <span> { parseInt(data.price).toLocaleString('en-US')}</span> <span className='currency'>{(this.props.Language == "en" ||  this.props.Language == "tr")? 'IQD': 'د.ع.'}</span> 
                                                                        </div>
                                                                        
                                                                        <pre>
                                                                            {
                                                                                this.props.Language == "en" ? data.desc_eng
                                                                                : this.props.Language == "krd" ? data.desc_krd
                                                                                : data.desc_ar
                                                                            }
                                                                        </pre>
                                                                        
                                                                    </div>
                                                                );
                                                            }} >
                                                                <div className='style-two'> 
                                                                    {
                                                                        (this.state.items?.[this.state.selectCategory?.id] &&
                                                                            this.state.items[this.state.selectCategory.id]?.[this.state.selectBrand.id]?.length > 0) && (
                                                                            <>
                                                                              {this.state.items[this.state.selectCategory.id][this.state.selectBrand.id]?.map(
                                                                                (item) => (
                                                                                  <Two key={item.id} Data={item} Language={this.props.Language} />
                                                                                )
                                                                              )}
                                                                            </>
                                                                          )
                                                                    } 
                                                                </div>
                                                            </PhotoProvider>
                                                        : <Loader></Loader>
                                                    }
                </div>

                <div 
                        className={`language-wrapper ${this.state.showLangSelect? '': 'd-none'}`} 
                        onClick={ () => this.handleShowLangSelect(false)} >
                    <div className={`language-select`}>
                        <div className='language-select-head'><span>Language</span> <i className="fa-solid fa-angle-up"></i></div>
                        <ul>
                            <li className={`${this.props.Language === "krd"? 'language-select-active': ''}`} onClick={() => this.handleChange('krd')}>کوردی</li>
                            <li className={`${this.props.Language === "ar"? 'language-select-active': ''}`} onClick={() => this.handleChange('ar')}>العربیة</li>
                            <li className={`${this.props.Language === "en"? 'language-select-active': ''}`} style={{'textAlign': 'left'}} onClick={() => this.handleChange('en')}>English</li>
                        </ul>
                    </div>
                </div>
                
            </div>
        );
    }
}

export default Menu;