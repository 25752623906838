import React, { Component } from 'react';
import { Link } from "react-router-dom";
import CatLoading from './catloading';

class Brand extends Component {

    data = this.props.Data;
    language = this.props.Language;

    render() {
        return (
            <div className={`brand-item ${this.props.Active?'category-brand-active':''}`} onClick={ () => this.props.handleBrand(this.data)} >
                <div className='brand-icon'>
                    <CatLoading Img={this.data.logo} />
                </div>
                <span className='brand-name'>
                    {
                        this.props.Language == "en" ? this.data['name_eng']
                        : this.props.Language == "krd" ? this.data.name_krd
                        : this.data.name_ar
                    }
                </span>
            </div>
        );
    }
}

export default Brand;