import React, { Component } from 'react';
import Imgloading from './itemloading';
import { PhotoView } from 'react-photo-view';
import gsap from 'gsap';

class Two extends Component {

    data = this.props.Data;
    language = this.props.Language;

    componentDidMount() {
        gsap.fromTo(".item", { opacity: 0, y:50 }, { opacity: 1, y:0, duration: 0.5 });
    }


    priceText(text){
        if(text == 'small')
            
            return (
                <span> 
                    {
                        this.props.Language == "en"? 'small'
                        : this.props.Language == "krd"? 'بچیک'
                        : 'صغیر'
                    } 
                </span>
            )
        else if(text == 'medium')
            return (
                <span>
                    {
                        this.props.Language == "en"? 'medium'
                        : this.props.Language == "krd" ? 'ناڤنجی'
                        : 'متوسط'
                    }
                </span>
            )
        else
            return <span>
                {
                    this.props.Language == "en" ? 'large'
                    : this.props.Language == "krd" ? 'مەزن'
                    : 'کبير'
                }
            </span>
    }

    render() {
        return (
            
                <div className={`item ${this.props.Language == "en"? 'item-eng': ''}`}>
                    <PhotoView key={this.data.id} src={this.data.image} overlay={this.data}>

                        <div className="image">
                            {/* <Imgloading Img={this.data.image} /> */}
                                <Imgloading Img={this.data.image} />

                        </div>
                    </PhotoView>

                    <div className="info">
                        <h5 style={ this.props.Language == "en"? {'fontFamily':'Inter'}: {}}>
                            {
                                this.props.Language == "en" ? this.data.name_eng
                                : this.props.Language == "krd" ? this.data.name_krd
                                : this.data.name_ar
                            } 
                        </h5>
                        <div className='price'> 
                        {
                            (this.data.price_small && this.data.price_max)? 
                                <>
                                    
                                    <span> 
                                        { this.priceText('small') } 
                                        {this.data.price_small} 
                                    </span>
                                    <span> 
                                        { this.priceText('medium') } 
                                        {this.data.price} 
                                    </span> 
                                    <span> 
                                        { this.priceText('big') }
                                        {this.data.price_max} 
                                    </span> 
                                </>
                            :
                            (this.data.price_small)?
                            <>
                                
                                <span> 
                                    { this.priceText('small') }
                                    {this.data.price_small} 
                                </span>

                                <span> 
                                    { this.priceText('medium') } 
                                    {this.data.price} 
                                </span> 

                            </>
                            :
                            (this.data.price_max)?
                            <>
                                <span> 
                                    { this.priceText('medium') }  
                                    {this.data.price} 
                                </span> 
                                <span> 
                                    { this.priceText('big') }
                                    {this.data.price_max} 
                                </span> 
                            </>
                            :
                            <span> { parseInt(this.data.price).toLocaleString('en-US')}  </span>
                        }

                            <span className='currency'>
                                {(this.props.Language == "en")? 'IQD': 'د.ع.'} 
                            </span>
                        </div>
                        {/* <pre>
                            {
                                this.props.Language == "en" ? this.data.desc_eng
                                : this.props.Language == "krd" ? this.data.desc_krd
                                : this.data.desc_ar
                            }
                            
                            <span style={ this.props.Language == "en"? {'fontFamily':'Inter'}: {}}>
                            {
                                this.props.Language == "en" ? 'Pieces'
                                : this.props.Language == "krd" ? 'پارچە'
                                : 'قطع'
                            } 
                            </span>

                        </pre> */}
                    </div>
                </div>
            
        );
    }
}

export default Two;