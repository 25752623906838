import React, { Component } from 'react';
import { Link, Navigate } from "react-router-dom";
import axios from 'axios';
import Item from './item';
import Loader from './../general/loader';
import gsap from 'gsap';
import backArrow from '../../assets/backArrow.png'

class Category extends Component {

    settings = this.props.Settings;

    state = {
        categories: [],
        load: false,
        back: false,
        showLangSelect: false,
    }

    async componentDidMount() {
        const {data} = await axios.get("https://manage.caviarmenu.com/api/brands/sgtcompany");
        this.setState({ categories: data }, ()=>{
            this.setState({load: true});
        });

        // gsap.fromTo(".head img", { opacity: 0, y:-1000 }, { opacity: 1, y:0, duration: 2.5, });
    }

    handleChange = e => {
        this.props.handleLangaugeSubmit(e);
    }

    handleShowLangSelect = e => {
        this.setState({showLangSelect: e});
    }


    render() {
        return (

            <>
                { this.state.back && <Navigate to="/" replace={true} /> }
                <div className='category'>

                    <div className="head">


                            <div className="language">
                                <div 
                                        className='language-active' 
                                        // style={{'direction': `${this.props.Language != "en"? 'rtl': 'ltr'}`}}
                                        onClick={ () => this.handleShowLangSelect(true) }>
                                            <i className="fa-solid fa-globe"></i>
                                            {
                                                this.props.Language == "en"? 'En'
                                                : this.props.Language == "krd"? 'Ku'
                                                : 'Ar'
                                            }
                                </div>
                                
                            </div>

                            <Link to={"/menu"}>
                                <img src={backArrow} className="backward" alt="" />
                            </Link>

                        <h3 style={ this.props.Language == "en"? {'fontFamily':'Inter'}: {}}>

                            {
                                this.props.Language == "en"? 'Brands'
                                : this.props.Language == "krd"? 'براند'
                                : 'ماركة'
                            }
                        </h3>
                    </div>
                    
                    <div className="category-list">
                        
                        {
                            this.state.load 
                            ?
                                this.state.categories.map( (category, index) => <Item key={index} Data = {category} Language = {this.props.Language} Index={index} Length={this.state.categories.length} ></Item>)
                            : 
                                <Loader></Loader>
                        }

                    </div>

                    <div 
                            className={`language-wrapper ${this.state.showLangSelect? '': 'd-none'}`} 
                            onClick={ () => this.handleShowLangSelect(false)} >
                        <div className={`language-select`}>
                            <div className='language-select-head'><span>Language</span> <i className="fa-solid fa-angle-up"></i></div>
                            <ul>
                                <li className={`${this.props.Language === "krd"? 'language-select-active': ''}`} onClick={() => this.handleChange('krd')}>کوردی</li>
                                <li className={`${this.props.Language === "ar"? 'language-select-active': ''}`} onClick={() => this.handleChange('ar')}>العربیة</li>
                                <li className={`${this.props.Language === "en"? 'language-select-active': ''}`} style={{'textAlign': 'left'}} onClick={() => this.handleChange('en')}>English</li>
                            </ul>
                        </div>
                    </div>

                </div>
            </>
        );
    }
}

export default Category;