import React, { Component } from 'react';
import { Link, Navigate } from "react-router-dom";
import gsap from 'gsap';

class Intro extends Component {

    settings = this.props.Settings;
    language = this.props.Language;

    state = {
        navigate: false
    }

    componentDidMount() {

        gsap.from(".head img", { opacity: 0, y: 100, duration: 1 });
        gsap.from(".head h3, p", { opacity: 0, y: 100, duration: 1.5 });

    }

    handleSubmit = event => {
        event.preventDefault();
        this.setState({ navigate : true});
    };
    
    handleChange = lang => {
        this.props.handleLangaugeSubmit(lang);
    }

    render() {
        return (
            <>
                { this.state.navigate && <Navigate to="/menu" replace={true} /> }


                <div className='intro' style={ { backgroundImage :`url(${this.settings.introCover})`, backgroundSize: 'cover', backgroundOrigin: 'bottom' } }>
                    <div className="head">
                        <img src={this.settings.logo} alt="" />
                    </div>
                    <form onSubmit={this.handleSubmit} >
                        <button type='submit' onClick={() => this.handleChange('krd')}> کوردی </button>
                        <button type='submit' onClick={() => this.handleChange('ar')}> العربیة </button>
                        <button type='submit' onClick={() => this.handleChange('en')}> English </button>
                    </form>

                    <div className="social">


                        { 
                            this.settings.instgram 
                            &&
                            <a href={this.settings.instgram}><i className="fa-brands fa-instagram"></i></a>
                        }

                        { 
                            this.settings.snapchat
                            &&
                            <a href={this.settings.snapchat}><i className="fa-brands fa-snapchat"></i></a>
                        }

                        { 
                            this.settings.titok
                            &&
                            <a href={this.settings.titok}><i className="fa-brands fa-tiktok"></i></a>
                        }

                        { 
                            this.settings.facebook
                            &&
                            <a href={this.settings.facebook}><i className="fa-brands fa-facebook-f"></i></a>
                        }

                    </div>


                    {/* <div className='send-feedback'>
                        <Link to={"/feedback"}>
                            <i className="fa-solid fa-message"></i> Feedback 
                        </Link>
                    </div> */}

                </div>
            </>
        );
    }
}

export default Intro;